import {
    INIT,
    INIT_PLAYLIST,
    PLAYLIST_DOWNLOAD_STARTED,
    PLAYLIST_DOWNLOAD_ENDED,
    PLAYLIST_DOWNLOAD_FAILED,
    PLAYLIST_DOWNLOAD_PERCENT_UPDATED,
    PLAYLIST_PARSE_STARTED,
    PLAYLIST_PARSE_ENDED,
    PLAYLIST_PARSE_PERCENT_UPDATED, PLAYLIST_DOWNLOAD_ERROR
} from "../actions/types";

export const initialState = {
    "url": '',
    "filename": '',
    "totalSize": 1,
    "downloadedSize": 0,
    "downloading": false,
    "downloadStarted": false,
    "downloadEnded": false,
    "parsing": false,
    "parseStarted": false,
    "parseEnded": false,
    "totalChannelCount": 1,
    "parsedChannelCount": 0,
};

export default function playlist(state = initialState, action) {
    switch (action.type) {
        case INIT:
            const initPlaylist = {};
            return Object.assign({}, state, Object.assign({}, state, initPlaylist));

        case INIT_PLAYLIST:
            return Object.assign({}, state, Object.assign({}, state, action.value));

        case PLAYLIST_DOWNLOAD_STARTED:
            return Object.assign({}, state, Object.assign({}, state, {
                'url': action.url,
                'filename': '',
                'downloadStarted': true,
                "downloading": true,
                "downloadEnded": false,
            }));

        case PLAYLIST_DOWNLOAD_ENDED:
            return Object.assign({}, state, Object.assign({}, state, {
                'downloadStarted': true,
                "downloading": false,
                "downloadEnded": true,
            }));

        case PLAYLIST_DOWNLOAD_FAILED:
            return Object.assign({}, state, Object.assign({}, state, {
                'downloadStarted': true,
                "downloading": false,
                "downloadEnded": true,
            }));

        case PLAYLIST_DOWNLOAD_PERCENT_UPDATED:
            return Object.assign({}, state, Object.assign({}, state, {
                "downloadedSize": action.downloaded,
                "totalSize": action.total,
            }));

        case PLAYLIST_PARSE_STARTED:
            return Object.assign({}, state, Object.assign({}, state, {
                "parsing": true,
                "parseStarted": true,
                "parseEnded": false,
            }));

        case PLAYLIST_PARSE_ENDED:
            return Object.assign({}, state, Object.assign({}, state, {
                "parsing": false,
                "parseStarted": true,
                "parseEnded": true,
            }));

        case PLAYLIST_PARSE_PERCENT_UPDATED:
            return Object.assign({}, state, Object.assign({}, state, {
                "parsedChannelCount": action.parsed,
                "totalChannelCount": action.total,
            }));

        case PLAYLIST_DOWNLOAD_ERROR:
            return Object.assign({}, state, Object.assign({}, state, {
                "url": '',
                "filename": '',
                "totalSize": 1,
                "downloadedSize": 0,
                "downloading": false,
                "downloadStarted": false,
                "downloadEnded": false,
                "parsing": false,
                "parseStarted": false,
                "parseEnded": false,
                "totalChannelCount": 1,
                "parsedChannelCount": 0,
            }));

        default:
            return state;
    }
}