import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { SidebarHeader, SidebarHeading, SidebarItem, SidebarLabel } from "../catalyst-components/sidebar";
import { Divider } from "../catalyst-components/divider";
import {selectChannel} from "../actions";

function ChannelsComponent() {
    const dispatch = useDispatch();
    const selectedGroupChannels = useSelector(state => state.channels.selectedGroupChannels);
    const selectedChannelUrl = useSelector(state => state.channels.selected.url);

    const listSelectedGroupChannels = selectedGroupChannels.map((c, index) => (
        <SidebarItem
            key={`channels-${index}`}
            current={selectedChannelUrl === c.url}
            href="play"
            onClick={() => dispatch(selectChannel(c))}
        >
            {c.tvg.logo !== '' && (
                <img className="h-[1rem] w-[1rem] rounded-full" src={c.tvg.logo} alt="logo"/>
            )}
            <SidebarLabel>{c.tvg.name}</SidebarLabel>
        </SidebarItem>
    ));

    if (listSelectedGroupChannels.length === 0) {
        return null;
    }

    return (
        <>
            {listSelectedGroupChannels}
        </>
    );
}

export default ChannelsComponent;
