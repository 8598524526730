import React from "react";

import {Heading, Subheading} from "../../catalyst-components/heading";
import {Divider} from "../../catalyst-components/divider";
import DashboardComponent from "../DashboardComponent";
import {useTranslation} from "react-i18next";

export default function Dash() {
    const { t } = useTranslation();
    return (
        <>
            <Heading>{t('dash.title')}</Heading>
            <Subheading>{t('dash.desc')}</Subheading>
            <Divider className="my-10 mt-6"/>
            <DashboardComponent/>
        </>

    );
}
