'use client';

import React from 'react';
import { Avatar } from '../catalyst-components/avatar';
import {
    Dropdown,
    DropdownButton,
    DropdownDivider,
    DropdownItem,
    DropdownLabel,
    DropdownMenu,
} from '../catalyst-components/dropdown';
import { Navbar, NavbarSpacer } from '../catalyst-components/navbar';
import {
    Sidebar,
    SidebarHeader,
    SidebarItem,
    SidebarLabel,
} from '../catalyst-components/sidebar';
import { SidebarLayout } from '../catalyst-components/sidebar-layout';
import {
    ChevronDownIcon,
    Cog8ToothIcon
} from '@heroicons/react/16/solid';
import {
    HomeIcon,
    QuestionMarkCircleIcon,
    LightBulbIcon
} from '@heroicons/react/20/solid';

import {useSelector} from "react-redux";
import TabsComponent from "./TabsComponent";
import StreamComponent from "./StreamComponent";
import {useTranslation} from "react-i18next";


function LayoutComponent({ children }) {
    const { t } = useTranslation();
    const activateWebRTC = useSelector(state => state.settings.activateWebRTC);

    return (
        <SidebarLayout
            navbar={
                <Navbar>
                    <NavbarSpacer />
                </Navbar>
            }
            sidebar={
                <Sidebar>
                    <SidebarHeader>
                        <Dropdown>
                            <DropdownButton as={SidebarItem}>
                                <Avatar className="size-10" src="/pleyr.svg" />
                                <SidebarLabel>{t('navigation.pleyr')}</SidebarLabel>
                                <ChevronDownIcon />
                            </DropdownButton>
                            <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
                                <DropdownItem href="settings">
                                    <Cog8ToothIcon />
                                    <DropdownLabel>{t('navigation.settings')}</DropdownLabel>
                                </DropdownItem>
                                <DropdownDivider />
                                <DropdownItem href="what">
                                    <QuestionMarkCircleIcon />
                                    <DropdownLabel>{t('navigation.whatIsIt')}</DropdownLabel>
                                </DropdownItem>
                                <DropdownDivider />
                                <DropdownItem href="how">
                                    <LightBulbIcon />
                                    <DropdownLabel>{t('navigation.howItWorks')}</DropdownLabel>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <SidebarItem href="">
                            <HomeIcon />
                            <SidebarLabel>{t('navigation.home')}</SidebarLabel>
                        </SidebarItem>
                    </SidebarHeader>
                    <TabsComponent />
                    {activateWebRTC ?
                        <StreamComponent/>
                        : null}
                </Sidebar>
            }
        >
            {children}
        </SidebarLayout>
    );
}

export default LayoutComponent;
