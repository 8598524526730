import React from "react";

import {Heading} from "../../catalyst-components/heading";
import {Divider} from "../../catalyst-components/divider";
import {Text} from "../../catalyst-components/text";
import Link from "../../catalyst-components/link";
import {Table, TableRow, TableHead, TableHeader, TableBody, TableCell} from "../../catalyst-components/table";
import { useLocalizedNavigate } from '../../lib/navigate'
import {useTranslation} from "react-i18next";


export default function Try() {
    const { t } = useTranslation()
    const navigate = useLocalizedNavigate();
    const pls = [
        {
            owner: 'iptv-org',
            name: 'try.playlists.mainPlaylist',
            url: 'https://iptv-org.github.io/iptv/index.m3u'
        },
        {
            owner: 'iptv-org',
            name: 'try.playlists.groupedByCategory',
            url: 'https://iptv-org.github.io/iptv/index.category.m3u'
        },
        {
            owner: 'iptv-org',
            name: 'try.playlists.groupedByCountry',
            url: 'https://iptv-org.github.io/iptv/index.country.m3u'
        },
        {
            owner: 'iptv-org',
            name: 'try.playlists.groupedByRegion',
            url: 'https://iptv-org.github.io/iptv/index.region.m3u'
        }
    ]
    return (
        <>
            <Heading>{t('try.header')}</Heading>
            <Text>{t('try.description')}</Text>
            <Divider className="my-10 mt-6"/>
            <Text>
                {t('try.detailedDescription')}
                <Link className="font-bold" target="_blank" rel="nofollow, noindex, noreferrer" href="https://github.com/iptv-org/iptv?tab=readme-ov-file#playlists">
                    {t('try.linkText')}
                </Link>
            </Text>

            <Table className="mt-6">
                <TableHead>
                    <TableRow>
                        <TableHeader>{t('try.tableHeaders.number')}</TableHeader>
                        <TableHeader>{t('try.tableHeaders.owner')}</TableHeader>
                        <TableHeader>{t('try.tableHeaders.name')}</TableHeader>
                        <TableHeader>{t('try.tableHeaders.link')}</TableHeader>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {pls.map((pl, index) => (
                        <TableRow key={index} href="#" onClick={() => navigate("settings", { state: { url: pl.url } })}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell className="font-medium">{pl.owner}</TableCell>
                            <TableCell>{t(pl.name)}</TableCell>
                            <TableCell>{pl.url}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>

    );
}
