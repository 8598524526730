import videojs from 'video.js';
import Mpegts from 'mpegts.js';

const Html5 = videojs.getTech('Html5');
const defaults = {
    mediaDataSource: {},
    config: {},
};

class MpegTsJs extends Html5 {
    constructor(options, ready) {
        options = videojs.mergeOptions(defaults, options);
        super(options, ready);
    }

    setSrc(src) {
        if (this.mpegtsPlayer) {
            // Is this necessary to change source?
            this.mpegtsPlayer.detachMediaElement();
            this.mpegtsPlayer.destroy();
        }

        const mediaDataSource = this.options_.mediaDataSource;
        const config = this.options_.config;

        mediaDataSource.type = mediaDataSource.type === undefined ? 'mpegts' : mediaDataSource.type;
        mediaDataSource.url = src;
        this.mpegtsPlayer = Mpegts.createPlayer(mediaDataSource, config);
        this.mpegtsPlayer.attachMediaElement(this.el_);
        this.mpegtsPlayer.load();
    }

    /**
     * Dispose of mpegtsjs.
     */
    dispose() {
        if (this.mpegtsPlayer) {
            this.mpegtsPlayer.detachMediaElement();
            this.mpegtsPlayer.destroy();
        }
        super.dispose();
    }
}

/**
 * Check if the MpegTsJs tech is currently supported.
 *
 * @return {boolean}
 *          - True if the MpegTsJs tech is supported.
 *          - False otherwise.
 */
MpegTsJs.isSupported = function () {
    return Mpegts && Mpegts.isSupported();
};

/**
 * MpegTsJs supported mime types.
 *
 * @constant {Object}
 */
MpegTsJs.formats = {
    // https://datatracker.ietf.org/doc/html/rfc3555#section-4.2.9
    'video/mp2t': 'TS',
};

/**
 * Check if the tech can support the given type
 *
 * @param {string} type
 *        The mimetype to check
 * @return {string} 'probably', 'maybe', or '' (empty string)
 */
MpegTsJs.canPlayType = function (type) {
    if (MpegTsJs.isSupported() && type in MpegTsJs.formats) {
        return 'maybe';
    }

    return '';
};

/**
 * Check if the tech can support the given source
 * @param {Object} srcObj
 *        The source object
 * @param {Object} options
 *        The options passed to the tech
 * @return {string} 'probably', 'maybe', or '' (empty string)
 */
MpegTsJs.canPlaySource = function (srcObj, options) {
    return MpegTsJs.canPlayType(srcObj.type);
};

// Include the version number.
MpegTsJs.VERSION = '__VERSION__';


export default MpegTsJs;
