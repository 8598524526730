import {
    PLAYLIST_DOWNLOAD_STARTED,
    SELECT_GROUP,
    SETTINGS_COLLAPSE_LEFT, SETTINGS_COLLAPSE_RIGHT, SETTINGS_DARK_MODE_TOGGLE, SETTINGS_LANGUAGE, SETTINGS_SELECT_TAB,
    SETTINGS_WEBRTC_TOGGLE
} from "../actions/types";

export const initialState = {
    'darkMode' : false,
    'collapseLeft' : false,
    'collapseRight' : false,
    'selectedTabIndex' : 0,
    'activateWebRTC' : false,
    'websocketUrl': 'wss://socket.pleyr.net/websocket',
    'language': 'en'
};

export default function settings(state = initialState, action) {
    switch (action.type) {

        case PLAYLIST_DOWNLOAD_STARTED:
            return Object.assign({}, state, Object.assign({}, state, {
                'selectedTabIndex' : 0,
            }));

        case SETTINGS_DARK_MODE_TOGGLE:
            return Object.assign({}, state, Object.assign({}, state, {
                'darkMode' : action.payload
            }));

        case SETTINGS_COLLAPSE_LEFT:
            console.log(state)
            return Object.assign({}, state, Object.assign({}, state, {
                'collapseLeft' : !state.collapseLeft
            }));

        case SETTINGS_COLLAPSE_RIGHT:
            return Object.assign({}, state, Object.assign({}, state, {
                'collapseRight' : !state.collapseRight
            }));

        case SELECT_GROUP:
            return Object.assign({}, state, Object.assign({}, state, {
                "selectedTabIndex": 1,
            }));

        case SETTINGS_SELECT_TAB:
            return Object.assign({}, state, Object.assign({}, state, {
                "selectedTabIndex": action.payload,
            }));
        case SETTINGS_WEBRTC_TOGGLE:
            return Object.assign({}, state, Object.assign({}, state, {
                'activateWebRTC' : action.payload
            }));
        case SETTINGS_LANGUAGE:
            return Object.assign({}, state, Object.assign({}, state, {
                'language' : action.payload
            }));
        default:
            return state;
    }
}