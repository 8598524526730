import {
    SOCKET_CONNECTED,
    SOCKET_DISCONNECTED,
    SOCKET_PEER_REQUESTED,
    SOCKET_PEER_CANCELED,
    SOCKET_PEER_ACCEPTED_SENT,
    SOCKET_PEER_ACCEPTED_RECEIVED,
    RTC_SDP_UPDATED_OFFER,
    RTC_SDP_UPDATED_ANSWER, SOCKET_PEER_REQUEST_SENT, SOCKET_PEER_REQUEST_RECEIVED, STREAM_TRACK_RECEIVED
} from "../actions/types";
import { useNavigate } from "react-router-dom";
export const initialState = {
    'socket_status': 'ready',
    'id': '',
    'name': '',
    'incomingRequests': [],
    'outgoingRequests': [],
    'peers': [],
};

export default function peer(state = initialState, action) {
    switch (action.type) {
        case SOCKET_CONNECTED:
            return Object.assign({}, state, Object.assign({}, state, {
                'socket_status': 'connected',
                'id': action.payload.id,
                'name': action.payload.name,
            }));

        case SOCKET_PEER_REQUEST_SENT:
            return Object.assign({}, state, Object.assign({}, state, {
                'outgoingRequests': action.payload.outgoingRequests,
            }));

        case SOCKET_PEER_REQUEST_RECEIVED:
            return Object.assign({}, state, Object.assign({}, state, {
                'incomingRequests': action.payload.incomingRequests,
            }));

        case SOCKET_PEER_ACCEPTED_SENT:
            return Object.assign({}, state, Object.assign({}, state, {
                'incomingRequests': action.payload.incomingRequests,
                'peers': action.payload.peers,
            }));

        case SOCKET_PEER_ACCEPTED_RECEIVED:
            return Object.assign({}, state, Object.assign({}, state, {
                'outgoingRequests': action.payload.outgoingRequests,
                'peers': action.payload.peers,
            }));
        case SOCKET_PEER_CANCELED:
            return Object.assign({}, state, Object.assign({}, state, {
                'incomingRequests': action.payload.incomingRequests,
                'outgoingRequests': action.payload.outgoingRequests
            }));

        case SOCKET_DISCONNECTED:
            return Object.assign({}, state, Object.assign({}, state, {
                'socket_status': 'false',
            }));

        case STREAM_TRACK_RECEIVED:
            return state;
        case RTC_SDP_UPDATED_OFFER:
            return state;

        case RTC_SDP_UPDATED_ANSWER:
            return state;

        default:
            return state;
    }
}