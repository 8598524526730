import React from 'react';
import { Provider } from 'react-redux';
import configureStore from './store';
import { createRoot } from 'react-dom/client';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import Home from "./components/pages/home";
import Play from "./components/pages/play";
import What from "./components/pages/what";
import Settings from "./components/pages/settings";
import How from "./components/pages/how";
import Dash from "./components/pages/dash";
import Try from "./components/pages/try";
import Issues from "./components/pages/issues";
import Contact from "./components/pages/contact";

import './i18n'
import './css/main.css';
import RouterComponent from "./components/RouterComponent";

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <Routes>
                <Route path="/:lang/*" element={<RouterComponent />}>
                    <Route index element={<Home />} />
                    <Route path="play" element={<Play />} />
                    <Route path="what" element={<What />} />
                    <Route path="settings" element={<Settings />} />
                    <Route path="how" element={<How />} />
                    <Route path="dash" element={<Dash />} />
                    <Route path="try" element={<Try />} />
                    <Route path="issues" element={<Issues />} />
                    <Route path="contact" element={<Contact />} />
                </Route>
                <Route path="*" element={<Navigate replace to="/en" />} />
            </Routes>
        </BrowserRouter>
    </Provider>
);
