import {Component} from 'react';

function Progress({processedCount, totalCount, progressingTitle}) {

    const percentage = Math.floor(processedCount / totalCount * 100);

    return (
        <div className="mt-6 max-w-3xl mx-auto p-6 shadow rounded">
            <div className="mb-2 flex justify-between items-center">
                <div className="flex items-center gap-x-3">
                    <div>
                        <p className="text-sm font-medium text-gray-800 dark:text-white">{progressingTitle}</p>
                        <p className="text-xs text-gray-500 dark:text-gray-500">{processedCount} / {totalCount}</p>
                    </div>
                </div>
                <div className="inline-flex items-center gap-x-2">
                    <a className="text-gray-500 hover:text-gray-800" href="#">
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round">
                            <rect width="4" height="16" x="6" y="4"/>
                            <rect width="4" height="16" x="14" y="4"/>
                        </svg>
                    </a>
                    <a className="text-gray-500 hover:text-gray-800" href="#">
                        <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24"
                             height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
                             strokeLinecap="round" strokeLinejoin="round">
                            <path d="M3 6h18"/>
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                            <line x1="10" x2="10" y1="11" y2="17"/>
                            <line x1="14" x2="14" y1="11" y2="17"/>
                        </svg>
                    </a>
                </div>
            </div>

            <div className="flex items-center gap-x-3 whitespace-nowrap">
                <div className="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                     role="progressbar" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">
                    <div
                        className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                        style={{width: percentage + "%"}}></div>
                </div>
                <div className="w-6 text-end">
                        <span
                            className="text-sm text-gray-800 dark:text-white">{percentage}%</span>
                </div>
            </div>
        </div>
    );
}

export default Progress;