import {Link} from "react-router-dom";

export default function Contact() {
    return (
        <div>
            new comp Home
            <Link to="/">back to app</Link>
        </div>
    );
}
