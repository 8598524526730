import React, {useState, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {socketSendMessage} from "../actions";

import {SidebarFooter, SidebarItem, SidebarLabel, SidebarSection} from "../catalyst-components/sidebar";
import {Dropdown, DropdownButton, DropdownDivider, DropdownItem, DropdownLabel, DropdownMenu} from "../catalyst-components/dropdown";
import {Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle} from '../catalyst-components/dialog'
import {Field, Label} from '../catalyst-components/fieldset'
import {Input} from '../catalyst-components/input'
import {Button} from '../catalyst-components/button'
import {Badge} from "../catalyst-components/badge";

import {ArrowRightStartOnRectangleIcon, ChevronUpIcon, UserCircleIcon} from "@heroicons/react/16/solid";
import {PlayIcon, ShareIcon} from "@heroicons/react/20/solid";

function StreamComponent() {

    const [openPeerConnect, setOpenPeerConnect] = useState(false);
    const dispatch = useDispatch();
    const peer = useSelector(state => state.peer);
    const selected = useSelector(state => state.channels.selected);
    const textInput = useRef(null);

    const handleConnectionRequest = (peerName) => {
        dispatch(socketSendMessage({
            'command': 'peer_requested',
            'name': peerName
        }));
        setOpenPeerConnect(false);
    };

    const handleAccept = (peerName) => {
        dispatch(socketSendMessage({
            'command': 'peer_accepted',
            'name': peerName
        }));
    };

    const handleCancel = (peerName) => {
        dispatch(socketSendMessage({
            'command': 'peer_canceled',
            'name': peerName
        }));
    };

    const copy = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(peer.name).then(() => {
            }, () => {
            });
        }
    };

    const incomingRequests = (
        <>
            {peer.incomingRequests.map((peerName, index) => (
                <SidebarFooter>
                    <Dropdown>
                        <DropdownButton as={SidebarItem}>
                        <span className="flex min-w-0 items-center gap-3">
                            <span className="min-w-0">
                                <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                                    Incoming Request
                                </span>
                                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                    {peerName}
                                </span>
                            </span>
                        </span>
                            <ChevronUpIcon/>
                        </DropdownButton>

                        <DropdownMenu className="min-w-64" anchor="top start">
                            <DropdownItem href="#" onClick={() => handleAccept(peerName)}>
                                <ArrowRightStartOnRectangleIcon/>
                                <DropdownLabel>Accept</DropdownLabel>
                            </DropdownItem>
                            <DropdownItem href="#" onClick={() => handleCancel(peerName)}>
                                <ArrowRightStartOnRectangleIcon/>
                                <DropdownLabel>Cancel</DropdownLabel>
                            </DropdownItem>
                        </DropdownMenu>

                    </Dropdown>
                </SidebarFooter>
            ))}
        </>
    );

    const outgoingRequests = (
        <>
            {peer.outgoingRequests.map((peerName, index) => (
                <SidebarSection key={`outgoing-${index}`}>
                    <SidebarItem>
                        <Badge color="purple">Outgoing Request</Badge>
                    </SidebarItem>
                </SidebarSection>
            ))}
        </>
    );

    const peers = (
        <>
            {peer.peers.map((peerName, index) => (
                <SidebarSection key={`peers-${index}`}>
                    <SidebarItem>
                        <Badge color="purple">peer</Badge>
                        <Badge color="purple">{peerName}</Badge>
                    </SidebarItem>
                </SidebarSection>
            ))}
        </>
    );

    return (
        <>
            {selected.streams?.length > 0 ? (
                <SidebarSection key="selected-stream">
                    <SidebarItem href="play">
                        <PlayIcon/>
                        <SidebarLabel>Play Stream</SidebarLabel>
                    </SidebarItem>
                </SidebarSection>
            ) : null}
            {incomingRequests}
            <SidebarFooter key="stream">
                <Dropdown>
                    <DropdownButton as={SidebarItem}>
                        <span className="flex min-w-0 items-center gap-3">
                            <span className="min-w-0">
                                <span
                                    className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                                    Stream
                                </span>
                                <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                                    {peer.name}
                                </span>
                            </span>
                        </span>
                        <ChevronUpIcon/>
                    </DropdownButton>
                    <DropdownMenu className="min-w-64" anchor="top start">
                        <DropdownItem href="#" onClick={() => copy()}>
                            <UserCircleIcon/>
                            <DropdownLabel>Copy Pleyr ID</DropdownLabel>
                        </DropdownItem>
                        <DropdownDivider/>
                        <DropdownItem href="#" onClick={() => setOpenPeerConnect(true)}>
                            <ShareIcon/>
                            <DropdownLabel>Connect Peer</DropdownLabel>
                        </DropdownItem>
                        <DropdownDivider/>

                        <DropdownItem href="#">
                            <ArrowRightStartOnRectangleIcon/>
                            <DropdownLabel>Disconnect</DropdownLabel>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                {outgoingRequests}
                {peers}
            </SidebarFooter>


            <Dialog open={openPeerConnect} onClose={() => {}}>
                <DialogTitle>Send Connection Request</DialogTitle>
                <DialogDescription>
                    You need to send a request for connection to your peer by the given Pleyr ID to you.
                </DialogDescription>
                <DialogBody>
                    <Field>
                        <Label>Pleyr ID</Label>
                        <Input ref={textInput} placeholder=""/>
                    </Field>
                </DialogBody>
                <DialogActions>
                    <Button plain onClick={() => setOpenPeerConnect(false)}>
                        Cancel
                    </Button>

                    <Button onClick={() => handleConnectionRequest(textInput.current.value)}>Request</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default StreamComponent;
