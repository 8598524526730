import { useNavigate, useParams } from 'react-router-dom';

export function useLocalizedNavigate() {
    const navigate = useNavigate();
    const { lang } = useParams(); // Assuming `lang` is part of the URL params

    function localizedNavigate(to, options) {
        console.log(lang, to, options);

        navigate(`/${lang}/${to}`, options);
    }

    return localizedNavigate;
}
