import {Heading} from "../../catalyst-components/heading";
import {Divider} from "../../catalyst-components/divider";
import {Text} from "../../catalyst-components/text";
import React from "react";
import {useTranslation} from "react-i18next";

export default function How() {
    const { t } = useTranslation()

    return (
        <div className="container mx-auto">
            <Heading>{t('how.title')}</Heading>
            <Divider className="my-4"/>

            <Text>
                {t('how.description')}
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('how.troubleshootingTitle')}</Heading>

            <Text>
                {t('how.troubleshootingDescription')}
                <ul className="list-disc list-inside mt-4">
                    <li>{t('how.troubleshootingSteps.chrome')}</li>
                    <li>{t('how.troubleshootingSteps.secureUrl')}</li>
                    <li>{t('how.troubleshootingSteps.cors')}</li>
                    <li>{t('how.troubleshootingSteps.insecureContent')}</li>
                </ul>
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('how.allowInsecureContentTitle')}</Heading>

            <Text>
                <ul className="list-disc list-inside">
                    <li>{t('how.allowInsecureContentSteps.padlock')}</li>
                    <li>{t('how.allowInsecureContentSteps.settings')}</li>
                    <li>{t('how.allowInsecureContentSteps.insecureContent')}</li>
                    <li>{t('how.allowInsecureContentSteps.allow')}</li>
                    <li>{t('how.allowInsecureContentSteps.menuLink')}</li>
                </ul>
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('how.videoFormatsTitle')}</Heading>

            <Text>
                {t('how.videoFormatsDescription')}
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('how.subtitleSupportTitle')}</Heading>

            <Text>
                {t('how.subtitleSupportDescription')}
                <ul className="list-disc list-inside">
                    <li>{t('how.subtitleSupportSteps.flags')}</li>
                    <li>{t('how.subtitleSupportSteps.search')}</li>
                    <li>{t('how.subtitleSupportSteps.findFlag')}</li>
                    <li>{t('how.subtitleSupportSteps.restart')}</li>
                </ul>
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('how.moreIssuesTitle')}</Heading>

            <Text>
                {t('how.moreIssuesContact')}
            </Text>
        </div>
    );
}
