import {Heading} from "../../catalyst-components/heading";
import {Divider} from "../../catalyst-components/divider";

import React from "react";
import { useLocation } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import PlaylistComponent from "../PlaylistComponent";
import SettingsComponent from "../SettingsComponent";

export default function Settings() {
    const { t } = useTranslation();

    const location = useLocation();
    const url = location?.state?.url || '';
    return (
        <>
            <Heading>{t('settings.title')}</Heading>
            <Divider className="my-10 mt-6"/>
            <PlaylistComponent url={url}/>

            <Divider className="my-10" soft/>

            <SettingsComponent/>
        </>
    );
}
