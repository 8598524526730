import {
    INIT_CHANNELS,
    INIT_PLAYLIST,
    PLAYLIST_DOWNLOAD_ENDED,
    PLAYLIST_DOWNLOAD_FAILED,
    PLAYLIST_DOWNLOAD_STARTED,
    PLAYLIST_PARSE_ENDED,
    PLAYLIST_PARSE_FAILED,
    PLAYLIST_PARSE_STARTED,
    SELECT_CHANNEL,
    SELECT_GROUP
} from "../actions/types";

const EVENT_ACTIONS = [
    INIT_PLAYLIST,
    PLAYLIST_DOWNLOAD_STARTED,
    PLAYLIST_DOWNLOAD_ENDED,
    PLAYLIST_DOWNLOAD_FAILED,
    PLAYLIST_PARSE_STARTED,
    PLAYLIST_PARSE_ENDED,
    PLAYLIST_PARSE_FAILED,
    INIT_CHANNELS,
    SELECT_CHANNEL,
    SELECT_GROUP
];
const event = store => next => action => {

    if (EVENT_ACTIONS.includes(action.type)) {
        if (typeof gtag === 'function') {
            // eslint-disable-next-line no-undef
            gtag('event', action.type.toLowerCase(), {});
        }
    }
    return next(action)
}

export default event