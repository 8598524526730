import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {SidebarItem, SidebarLabel } from "../catalyst-components/sidebar";
import { Badge } from "../catalyst-components/badge";
import {selectGroup} from "../actions";

function GroupComponent() {
    const dispatch = useDispatch();
    const chObj = useSelector(state => state.channels.list); // Adjust based on your actual state structure
    const selectedGroupName = useSelector(state => state.channels.selectedGroupName);

    const listItems = Object.keys(chObj).map((c, index) => (
        <SidebarItem key={`groups-${index}`} current={selectedGroupName === c} href="#" onClick={() => dispatch(selectGroup(c, chObj[c]))}>
            <SidebarLabel>{c}</SidebarLabel>
            <Badge color="purple">{chObj[c].length}</Badge>
        </SidebarItem>
    ));

    if (Object.keys(chObj).length === 0) {
        return null;
    }

    return (
        <>
            {listItems}
        </>
    );
}

export default GroupComponent;
