import {configureStore} from '@reduxjs/toolkit'

// import monitorReducersEnhancer from './enhancers/monitorReducers'
// import loggerMiddleware from './middleware/logger'
import eventMiddleware from './middleware/event'
import socketMiddleware from './middleware/socket'
import rtcMiddleware from './middleware/rtc'
// import localStorageMiddleware from './middleware/storage'
import rootReducer from './reducers'
// import { loadState } from './lib/load';

export default function configureAppStore(preloadedState) {

    //const preloadedState = loadState();

    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
            })

                 // .prepend(loggerMiddleware)
                .prepend(eventMiddleware)
                .prepend(socketMiddleware())
                .prepend(rtcMiddleware()),
                //.prepend(localStorageMiddleware),
        preloadedState,
        //enhancers: [monitorReducersEnhancer]
    })

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        //module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
    }

    return store
}