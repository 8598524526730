import {combineReducers} from "redux";
import playlist, {initialState as playlistInitialState} from './playlist'
import channels, {initialState as channelsInitialState} from './channels'
import peer, {initialState as peerInitialState} from './peer'
import settings, {initialState as settingsInitialState} from './settings'

const initialState = Object.assign({}, playlistInitialState, channelsInitialState, peerInitialState, settingsInitialState);

const rootReducer = combineReducers
(
    {playlist, channels, peer, settings},
    initialState
);

export default rootReducer;