// socket.js
import {
    rtcSdpUpdatedAnswer,
    rtcSdpUpdatedOffer,
    socketConnected,
    socketPeerAcceptedSent,
    socketPeerAcceptedReceived,
    socketPeerCanceled,
    socketPeerRequestReceived,
    socketPeerRequestSent,
    rtcSdpIcecandidate
} from "../actions";
import { SETTINGS_WEBRTC_TOGGLE, SOCKET_SEND_MESSAGE } from "../actions/types";

const socketMiddleware = () => {
    let ws = null;

    return storeAPI => next => action => {
        switch (action.type) {

            case SETTINGS_WEBRTC_TOGGLE:
                if(action.payload === false) {
                    if (ws) {
                        ws.close();
                        ws = null;
                    }
                    break;
                }

                const url = storeAPI.getState().settings.websocketUrl
                console.log(url);
                ws = new WebSocket(url);

                ws.onopen = () => {
                    console.log('socket is open');
                };

                ws.onmessage = (event) => {
                    const jsonData = JSON.parse(event.data);

                    switch (jsonData.action) {
                        case "peer_connection_init":
                            storeAPI.dispatch(socketConnected(jsonData.payload));
                            break;

                        case "peer_connection_request_sent":
                            storeAPI.dispatch(socketPeerRequestSent(jsonData.payload));
                            break;

                        case "peer_connection_request_received":
                            storeAPI.dispatch(socketPeerRequestReceived(jsonData.payload));
                            break;

                        case "peer_connection_accepted_sent":
                            storeAPI.dispatch(socketPeerAcceptedSent(jsonData.payload));
                            break;

                        case "peer_connection_accepted_received":
                            storeAPI.dispatch(socketPeerAcceptedReceived(jsonData.payload));
                            break;

                        case "peer_connection_canceled":
                            storeAPI.dispatch(socketPeerCanceled(jsonData.payload));
                            break;

                        case "rtc_sdp_updated_offer":
                            storeAPI.dispatch(rtcSdpUpdatedOffer(jsonData.payload));
                            break;

                        case "rtc_sdp_update_answer":
                            storeAPI.dispatch(rtcSdpUpdatedAnswer(jsonData.payload));
                            break;

                        case "rtc_sdp_icecandidate":
                            storeAPI.dispatch(rtcSdpIcecandidate(jsonData.payload));
                            break;

                        default:
                    }
                };
                break;

            case SOCKET_SEND_MESSAGE:
                if (ws) {
                    ws.send(JSON.stringify(action.payload));
                }
                break;

            default:
                break;
        }

        return next(action);
    };
};

export default socketMiddleware;
