const Parser = {}

Parser.test = async (content, options) => {
    const split = content.split(/(?=#EXTINF+)/g);
    let channels = [];
    let total = split.length;
    let promises = [];
    split.forEach((l, i) => {

        let p = new Promise((resolve, reject) => {

            if (/^#EXTM3U/.test(l)) {
                reject(new Error('header'));
                total--;
                return;
            }

            if (/\.flv/.test(l)) {
                reject(new Error('flv'));
                total--;
                return;
            }

            if (i % 100 === 0)
                if (typeof options.onParseProgress === "function") {
                    options.onParseProgress({'total': total, 'loaded': i + 1});
                }

            let extL = l.split('\n');

            let infoL = extL[0].trim();
            let urlL = extL[1].trim();

            if (urlL === '') {
                reject(new Error('No Url'));
            }

            let streamName = '';
            //find stream name
            if (infoL.getAttribute('tvg-id') === '' && infoL.getAttribute('tvg-name') === '') {
                const pattern = /[^,]+$/;
                const match = infoL.match(pattern);

                if (match) {
                    streamName = match[0];
                } else {
                    reject(new Error('No tvg id'));
                }
            } else if (infoL.getAttribute('tvg-name') !== '') {
                streamName = infoL.getAttribute('tvg-name');
            } else {
                streamName = infoL.getAttribute('tvg-id');
            }

            let groupTitle = infoL.getAttribute('group-title');
            if (infoL.getAttribute('group-title') === '') {
                groupTitle = 'Unknown Category';
            }
            resolve(
                {
                    url: urlL,
                    tvg: {
                        id: infoL.getAttribute('tvg-id'),
                        name: streamName,
                        logo: infoL.getAttribute('tvg-logo'),
                        url: infoL.getAttribute('tvg-url'),
                        rec: infoL.getAttribute('tvg-rec'),
                        shift: infoL.getAttribute('tvg-shift')
                    },
                    group: groupTitle
                });
        })
        promises.push(p);
    });
    const results = await Promise.all(promises.map(p => p.catch(e => e)));
    const validResults = results.filter(result => !(result instanceof Error));
    const groups = groupBy(validResults, ({group}) => group);
    if (typeof options.onParseEnd === "function") {
        options.onParseEnd(groups);
    }
}

const groupBy = (values, keyFinder) => {
    // using reduce to aggregate values
    return values.reduce((a, b) => {
        // depending upon the type of keyFinder
        // if it is function, pass the value to it
        // if it is a property, access the property
        const key = typeof keyFinder === 'function' ? keyFinder(b) : b[keyFinder];

        // aggregate values based on the keys
        if (!a[key]) {
            a[key] = [b];
        } else {
            a[key] = [...a[key], b];
        }

        return a;
    }, {});
};

// eslint-disable-next-line no-extend-native
String.prototype.getName = function () {
    let info = this.replace(/="(.*?)"/g, '')
    let parts = info.split(/,(.*)/)

    return parts[1] || ''
}

// eslint-disable-next-line no-extend-native
String.prototype.getAttribute = function (name) {
    let regex = new RegExp(name + '="(.*?)"', 'gi')
    let match = regex.exec(this)

    return match && match[1] ? match[1] : ''
}

module.exports = Parser