import * as types from './types';
import {
    PLAYLIST_DOWNLOAD_ERROR,
    RTC_SDP_ICECANDIDATE,
    RTC_SDP_UPDATED_ANSWER, SETTINGS_DARK_MODE_TOGGLE, SETTINGS_SELECT_TAB, SETTINGS_WEBRTC_TOGGLE,
    SOCKET_PEER_REQUESTED,
    SOCKET_SEND_MESSAGE,
    STREAM_TRACK_ADD,
    STREAM_TRACK_RECEIVED
} from "./types";

export const init = () => ({type: types.INIT});

export const initPlaylist = (value) => ({type: types.INIT_PLAYLIST, value});


export const startPlaylistDownload = (url) => ({type: types.PLAYLIST_DOWNLOAD_STARTED, url: url});
export const endPlaylistDownload = () => ({type: types.PLAYLIST_DOWNLOAD_ENDED});
export const failPlaylistDownload = () => ({type: types.PLAYLIST_DOWNLOAD_FAILED});
export const percentUpdatePlaylistDownload = (downloaded, total) => ({
    type: types.PLAYLIST_DOWNLOAD_PERCENT_UPDATED,
    downloaded: downloaded,
    total: total
});

export const startPlaylistParse = () => ({type: types.PLAYLIST_PARSE_STARTED});
export const endPlaylistParse = () => ({type: types.PLAYLIST_PARSE_ENDED});
export const failPlaylistParse = () => ({type: types.PLAYLIST_PARSE_FAILED});
export const percentUpdatePlaylistParse = (parsed, total) => ({
    type: types.PLAYLIST_PARSE_PERCENT_UPDATED,
    parsed: parsed,
    total: total
});

export const initChannels = (value) => ({
    type: types.INIT_CHANNELS,
    list: value
});

export const selectChannel = (value) => ({type: types.SELECT_CHANNEL, 'channel': value});

export const selectGroup = (name, list) => ({
    type: types.SELECT_GROUP,
    name: name,
    channels: list
});

export const socketRequested = (data) => ({type: types.SOCKET_REQUESTED, 'payload': data});
export const socketConnected = (data) => ({type: types.SOCKET_CONNECTED, 'payload': data});
export const socketSendMessage = (data) => ({type: types.SOCKET_SEND_MESSAGE, 'payload': data});

export const socketPeerRequestSent = (data) => ({type: types.SOCKET_PEER_REQUEST_SENT, 'payload': data});
export const socketPeerRequestReceived = (data) => ({type: types.SOCKET_PEER_REQUEST_RECEIVED, 'payload': data});

export const socketPeerAcceptedSent = (data) => ({type: types.SOCKET_PEER_ACCEPTED_SENT, 'payload': data});
export const socketPeerAcceptedReceived = (data) => ({type: types.SOCKET_PEER_ACCEPTED_RECEIVED, 'payload': data});
export const socketPeerCanceled = (data) => ({type: types.SOCKET_PEER_CANCELED, 'payload': data});

export const rtcSdpUpdatedOffer = (data) => ({type: types.RTC_SDP_UPDATED_OFFER, 'payload': data});
export const rtcSdpUpdatedAnswer = (data) => ({type: types.RTC_SDP_UPDATED_ANSWER, 'payload': data});

export const streamTrackAdd = (data) => ({type: types.STREAM_TRACK_ADD, 'payload': data});
export const streamTrackReceived = (data) => ({type: types.STREAM_TRACK_RECEIVED, 'payload': data});

export const rtcSdpIcecandidate = (data) => ({type: types.RTC_SDP_ICECANDIDATE, 'payload': data});

export const settingsDarkModeToggle = (data) => ({type: types.SETTINGS_DARK_MODE_TOGGLE, 'payload': data});
export const settingsCollapseLeft = () => ({type: types.SETTINGS_COLLAPSE_LEFT});
export const settingsCollapseRight = () => ({type: types.SETTINGS_COLLAPSE_RIGHT});
export const settingsSelectTab = (data) => ({type: types.SETTINGS_SELECT_TAB, 'payload': data});
export const playlistDownloadError = (error) => ({type: types.PLAYLIST_DOWNLOAD_ERROR, 'payload': error});
export const settingsWebrtcToggle = (data) => ({type: types.SETTINGS_WEBRTC_TOGGLE, 'payload': data});
export const settingsLanguage = (data) => ({type: types.SETTINGS_LANGUAGE, 'payload': data});