import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingsDarkModeToggle, settingsWebrtcToggle } from "../actions";
import { useTranslation } from 'react-i18next';

import { Subheading } from "../catalyst-components/heading";
import { Switch } from "../catalyst-components/switch";
import { Badge } from "../catalyst-components/badge";

function SettingsComponent() {
    const dispatch = useDispatch();
    const { activateWebRTC, darkMode } = useSelector(state => state.settings);
    const { t } = useTranslation();

    return (
        <>
            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading>{t('settings.enableStreaming')} <Badge color="red">{t('common.experimental')}</Badge></Subheading>
                </div>
                <div className="space-y-4">
                    <Switch checked={activateWebRTC} onChange={(bool) => dispatch(settingsWebrtcToggle(bool))} aria-label={t('settings.enableStreaming')} name="web-rtc"/>
                </div>

                <div className="space-y-1">
                    <Subheading>{t('settings.enableDarkMode')}</Subheading>
                </div>
                <div className="space-y-4">
                    <Switch checked={darkMode} onChange={(bool) => dispatch(settingsDarkModeToggle(bool))} aria-label={t('settings.enableDarkMode')} name="dark-mode"/>
                </div>
            </section>
        </>
    );
}

export default SettingsComponent;
