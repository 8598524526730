export const INIT = 'INIT';

//playlist
export const INIT_PLAYLIST = 'INIT_PLAYLIST';
export const PLAYLIST_DOWNLOAD_STARTED = 'PLAYLIST_DOWNLOAD_STARTED';
export const PLAYLIST_DOWNLOAD_PERCENT_UPDATED = 'PLAYLIST_DOWNLOAD_PERCENT_UPDATED';
export const PLAYLIST_DOWNLOAD_ENDED = 'PLAYLIST_DOWNLOAD_ENDED';
export const PLAYLIST_DOWNLOAD_FAILED = 'PLAYLIST_DOWNLOAD_FAILED';
export const PLAYLIST_PARSE_STARTED = 'PLAYLIST_PARSE_STARTED';
export const PLAYLIST_PARSE_ENDED = 'PLAYLIST_PARSE_ENDED';
export const PLAYLIST_PARSE_FAILED = 'PLAYLIST_PARSE_FAILED';
export const PLAYLIST_PARSE_PERCENT_UPDATED = 'PLAYLIST_PARSE_PERCENT_UPDATED';
export const INIT_CHANNELS = 'INIT_CHANNELS';
export const SELECT_CHANNEL = 'SELECT_CHANNEL';
export const SELECT_GROUP = 'SELECT_GROUP';

export const SOCKET_REQUESTED = 'SOCKET_REQUESTED';
export const SOCKET_CONNECTED = 'SOCKET_CONNECTED';
export const SOCKET_SEND_MESSAGE = 'SOCKET_SEND_MESSAGE';
export const SOCKET_MESSAGE_RECEIVED = 'SOCKET_MESSAGE_RECEIVED'
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED';
export const SOCKET_PEER_REQUEST_SENT = 'SOCKET_PEER_REQUEST_SENT';
export const SOCKET_PEER_REQUEST_RECEIVED = 'SOCKET_PEER_REQUEST_RECEIVED';
export const SOCKET_PEER_ACCEPTED_SENT = 'SOCKET_PEER_ACCEPTED_SENT';
export const SOCKET_PEER_ACCEPTED_RECEIVED = 'SOCKET_PEER_ACCEPTED_RECEIVED';
export const SOCKET_PEER_CANCELED = 'SOCKET_PEER_CANCELED';


export const RTC_SEND_MESSAGE = 'RTC_SEND_MESSAGE';
export const RTC_SDP_UPDATED_OFFER = 'RTC_SDP_UPDATED_OFFER';
export const RTC_SDP_UPDATED_ANSWER = 'RTC_SDP_UPDATED_ANSWER';

export const RTC_SDP_ICECANDIDATE = 'RTC_SDP_ICECANDIDATE';

export const STREAM_TRACK_ADD = 'STREAM_TRACK_ADD';
export const STREAM_TRACK_RECEIVED = 'STREAM_TRACK_RECEIVED';

export const SETTINGS_DARK_MODE_TOGGLE = 'SETTINGS_DARK_MODE_TOGGLE';
export const SETTINGS_COLLAPSE_LEFT = 'SETTINGS_COLLAPSE_LEFT';
export const SETTINGS_COLLAPSE_RIGHT = 'SETTINGS_COLLAPSE_RIGHT';
export const SETTINGS_SELECT_TAB = 'SETTINGS_SELECT_TAB';
export const PLAYLIST_DOWNLOAD_ERROR = 'PLAYLIST_DOWNLOAD_ERROR';
export const SETTINGS_WEBRTC_TOGGLE = 'SETTINGS_WEBRTC_TOGGLE';
export const SETTINGS_LANGUAGE = 'SETTINGS_LANGUAGE';

