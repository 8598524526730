import {
    RTC_SDP_UPDATED_OFFER,
    RTC_SDP_UPDATED_ANSWER,
    RTC_SEND_MESSAGE,
    SOCKET_PEER_ACCEPTED_SENT,
    SOCKET_PEER_ACCEPTED_RECEIVED,
    SOCKET_PEER_REQUEST_SENT,
    STREAM_TRACK_ADD,
    RTC_SDP_ICECANDIDATE, SOCKET_CONNECTED,
} from "../actions/types";

import {rtcSdpUpdated, socketSendMessage, streamTrackReceived} from "../actions";

const rtc = () => {
    return rtcApi => {

        const pc = new RTCPeerConnection({
            iceServers: [{"urls": "stun:stun.l.google.com:19302"}]
        });

        const dc = pc.createDataChannel("channel");
        window.rtcPeerConnection = pc;
        window.channel = dc;

        pc.ondatachannel = e => {
            const ch = e.channel;
            ch.onmessage = e => {
                // console.log(e.data)
            }
            ch.onopen = e => {
                // console.log("open!")
            }
            ch.onclose = e => {
                // console.log("closed!");
            }
            ch.channel = ch;
        }

        pc.ontrack = (ev) => {
            rtcApi.dispatch(streamTrackReceived(ev.streams));
        }


        return next => async action => {
            if (action.type === SOCKET_CONNECTED) {
                pc.onnegotiationneeded = (env) => {
                    pc.createOffer()
                        .then((offer) => {
                            return pc.setLocalDescription(offer)
                        })
                        .then(() => {
                            rtcApi.dispatch(socketSendMessage({
                                    'line': 44,
                                    'command': 'sdp_update_offer',
                                    'offer': pc.localDescription
                                }
                            ))
                        })
                        .catch(reportError);
                }
            }

            if (action.type === STREAM_TRACK_ADD) {
                pc.addTrack(action.payload.track, action.payload.stream);
            }

            if (action.type === SOCKET_PEER_REQUEST_SENT) {

            }

            if (action.type === SOCKET_PEER_ACCEPTED_SENT) {

            }

            if (action.type === SOCKET_PEER_ACCEPTED_RECEIVED) {
                pc.onicecandidate = (event) => {
                    rtcApi.dispatch(socketSendMessage({
                            'command': 'sdp_icecandidate',
                            'ice': event.candidate
                        }
                    ))
                }

                pc.createOffer().then(
                    (offer) => {
                        rtcApi.dispatch(socketSendMessage({
                                'line': 85,
                                'command': 'sdp_update_offer',
                                'offer': offer
                            }
                        ))
                        return pc.setLocalDescription(offer);
                    }
                )
            }

            if (action.type === RTC_SEND_MESSAGE) {
                dc.send(JSON.stringify(action.payload));
            }

            if (action.type === RTC_SDP_UPDATED_OFFER) {
                await pc.setRemoteDescription(action.payload.offer);
                pc.createAnswer()
                    .then(answer => {
                            rtcApi.dispatch(socketSendMessage({
                                    'command': 'sdp_update_answer',
                                    'answer': answer
                                }
                            ))
                            return pc.setLocalDescription(answer)
                        }
                    )
            }

            if (action.type === RTC_SDP_UPDATED_ANSWER) {
                if (pc.signalingState === 'stable') {
                    await Promise.all([
                        pc.setLocalDescription({type: "rollback"}),
                        pc.setRemoteDescription(action.payload.answer)
                    ]);
                    dc.send('message from me');
                } else {
                    await pc.setRemoteDescription(action.payload.answer);
                }
            }

            if (action.type === RTC_SDP_ICECANDIDATE) {
                try {
                    await pc.addIceCandidate(action.payload.ice);
                } catch (e) {
                    console.error(e);
                }
            }

            return next(action);
        }
    }
}
export default rtc;
window.rtc = rtc;