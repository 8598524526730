import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {streamTrackAdd} from "../actions";

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import MpegTsJs from '../lib/videojs-mpegts.plugin';

import { ShareIcon } from "@heroicons/react/20/solid";
import {Heading, Subheading} from "../catalyst-components/heading";
import {Divider} from "../catalyst-components/divider";
import {Text} from "../catalyst-components/text";
import {Button} from "../catalyst-components/button";

function VideoPlayerComponent() {
    const dispatch = useDispatch();
    const selected = useSelector(state => state.channels.selected);
    const peers = useSelector(state => state.peer.peers);
    const playerRef = useRef(null);
    const videoElementRef = useRef(null);

    useEffect(() => {
        // ComponentDidMount
        if (selected.url) {
            handleClick(selected.url);
        }

        return () => {
            // ComponentWillUnmount
            if (playerRef.current) {
                playerRef.current.dispose();
            }
        }
    }, []); // Empty dependency array ensures this effect runs only once on mount

    useEffect(() => {
        // componentDidUpdate for selected.url
        if (selected.url) {
            handleClick(selected.url);
        }
    }, [selected.url]);

    useEffect(() => {
        // componentDidUpdate for selected.streams
        if (selected.streams) {
            showStream(selected.streams);
        }
    }, [selected.streams]);

    const getTypeByUrl = (url) => {
        const ext = url.split('.').pop();

        let type = 'video/mp2t';

        switch (ext) {
            case 'm3u8':
                type = 'application/x-mpegURL';
                break;
            case 'mp4':
                type = 'video/mp4';
                break;
            case 'mkv':
                type = 'video/mp4';
                break;
            case 'avi':
                type = 'video/mp4';
                break;
            case 'm3u':
                type = 'application/x-mpegURL';
                break;
        }

        return type;
    }
    const handleClick = (url) => {
        if (playerRef.current) {
            playerRef.current.dispose();
        }

        const videoNode = document.getElementById('playerNode');
        const videoElement = document.createElement('video');
        videoElement.classList.add('video-js', 'vjs-theme-city');
        videoElement.id = "videojsElement";
        videoNode.appendChild(videoElement);
        videoElementRef.current = videoElement;

        videojs.MpegTsJs = MpegTsJs;
        videojs.registerTech('MpegTsJs', MpegTsJs);
        videojs.log();
        videojs.log.level('all');

        const videoJsOptions = {
            sources: [{ src: url, type: getTypeByUrl(url) }],
            autoplay: true,
            controls: true,
            fluid: true,
            fill: true,
            liveui: true,
            controlBar: {
                playToggle: true,
                volumePanel: true,
                pictureInPictureToggle: true,
                progressControl: true,
                currentTimeDisplay: true,
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: true,
                playbackRateMenuButton: false
            }
        };

        playerRef.current = videojs(videoElement, videoJsOptions);

        playerRef.current.on('error', () => {
            console.error(playerRef.current.error());
        });
    };

    const showStream = (stream) => {
        const videoNode = document.getElementById('playerNode');
        const videoElement = document.createElement('video');
        videoElement.classList.add('video-js');
        videoElement.id = "videojsElement";
        videoNode.appendChild(videoElement);
        videoElementRef.current = videoElement;

        const playerOptions = {
            autoplay: true,
            controls: true,
            fluid: true,
            fill: true,
            liveui: true,
            controlBar: {
                playToggle: true,
                volumePanel: false,
                pictureInPictureToggle: false,
                progressControl: true,
                currentTimeDisplay: true,
                timeDivider: true,
                durationDisplay: true,
                remainingTimeDisplay: true,
                playbackRateMenuButton: false
            }
        };

        playerRef.current = videojs(videoElement, playerOptions);
        videoElement.srcObject = stream[0];
    };

    const stream = () => {
        const fps = 0;
        const el = document.getElementsByTagName("video")[0];
        const stream = el.captureStream(fps);
        for (const track of stream.getTracks()) {
            dispatch(streamTrackAdd({'track': track, 'stream': stream}));
        }
    };

    return (
        <>
            <Heading>{selected?.tvg?.name || ''}</Heading>
            <div className="flex items-center justify-between w-5/6">
                <Subheading>{selected?.group || ''}</Subheading>
                {selected.url && peers.length > 0 ? (
                    <Button onClick={stream}>
                        <ShareIcon /> Stream
                    </Button>
                ) : null}
            </div>
            <Divider className="my-4"/>
            <div id="playerNode" className="w-5/6"></div>
            <Divider className="my-4"/>
            {selected.url ? <Text>{selected.url}</Text> : null}
        </>
    );
}

export default VideoPlayerComponent;