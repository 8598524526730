import {
    INIT_CHANNELS, PLAYLIST_DOWNLOAD_STARTED, SELECT_CHANNEL, SELECT_GROUP, STREAM_TRACK_RECEIVED
} from "../actions/types";

export const initialState = {
    'list': {},
    'categories': [],
    'category': 'all',
    'page': 1,
    'perPage': 20,
    'selected': {
        'url': null,
        'streams': null
    },
    'selectedGroupChannels': []
};

export default function channels(state = initialState, action) {
    switch (action.type) {

        case PLAYLIST_DOWNLOAD_STARTED:
            return Object.assign({}, state, Object.assign({}, state, {
                'list': {},
                'categories': [],
                'category': 'all',
                'page': 1,
                'perPage': 20,
                'selected': {
                    'url': null,
                    'streams': null
                },
                'selectedGroupChannels': []
            }));

        case INIT_CHANNELS:
            return Object.assign({}, state, Object.assign({}, state, {
                "list": action.list,
            }));

        case SELECT_CHANNEL:
            return Object.assign({}, state, Object.assign({}, state, {
                "selected": action.channel,
            }));

        case SELECT_GROUP:
            return Object.assign({}, state, Object.assign({}, state, {
                "selectedGroupName": action.name,
                "selectedGroupChannels": action.channels
            }));

        case STREAM_TRACK_RECEIVED:
            return Object.assign({}, state, Object.assign({}, state, {
                "selected": {
                    'url': null,
                    'streams': action.payload
                },
            }));
        default:
            return state;
    }
}