import React from 'react';
import {Text} from "../catalyst-components/text";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

function DashboardComponent() {
    const { t } = useTranslation();
    const channelList = useSelector(state => state.channels.list);
    
    let totalCount = 0;

    Object.keys(channelList).forEach(key => {
        totalCount += channelList[key].length;
    });
    return (
        <>
        <div className="mt-4 grid gap-8 sm:grid-cols-2 xl:grid-cols-4">
            <div>
                <Text className="mt-6 lg:text-lg font-medium sm:text-sm"><strong>{t('dash.totalGroupsCount')}</strong></Text>
                <Text className="mt-3 lg:text-lg">{Object.keys(channelList).length}</Text>
            </div>

            <div>
                <Text className="mt-6 lg:text-lg"><strong>{t('dash.totalChannelsCount')}</strong></Text>
                <Text className="mt-3 lg:text-lg">{totalCount}</Text>
            </div>

        </div>

            {/*<Subheading className="mt-14">Recent orders</Subheading>*/}
            {/*<Table className="mt-4 [--gutter:theme(spacing.6)] lg:[--gutter:theme(spacing.10)]">*/}
            {/*    <TableHead>*/}
            {/*        <TableRow>*/}
            {/*            <TableHeader>Order number</TableHeader>*/}
            {/*            <TableHeader>Purchase date</TableHeader>*/}
            {/*            <TableHeader>Customer</TableHeader>*/}
            {/*            <TableHeader>Event</TableHeader>*/}
            {/*            <TableHeader className="text-right">Amount</TableHeader>*/}
            {/*        </TableRow>*/}
            {/*    </TableHead>*/}
            {/*    <TableBody>*/}
            {/*        /!*{orders.map((order) => (*!/*/}
            {/*        /!*    <TableRow key={order.id} href={order.url} title={`Order #${order.id}`}>*!/*/}
            {/*        /!*        <TableCell>{order.id}</TableCell>*!/*/}
            {/*        /!*        <TableCell className="text-zinc-500">{order.date}</TableCell>*!/*/}
            {/*        /!*        <TableCell>{order.customer.name}</TableCell>*!/*/}
            {/*        /!*        <TableCell>*!/*/}
            {/*        /!*            <div className="flex items-center gap-2">*!/*/}
            {/*        /!*                <Avatar src={order.event.thumbUrl} className="size-6"/>*!/*/}
            {/*        /!*                <span>{order.event.name}</span>*!/*/}
            {/*        /!*            </div>*!/*/}
            {/*        /!*        </TableCell>*!/*/}
            {/*        /!*        <TableCell className="text-right">US{order.amount.usd}</TableCell>*!/*/}
            {/*        /!*    </TableRow>*!/*/}
            {/*        /!*))}*!/*/}
            {/*    </TableBody>*/}
            {/*</Table>*/}

        </>
    );
}
export default DashboardComponent;

