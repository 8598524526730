import React from 'react';
import {
    SidebarBody,
    SidebarFooter,
    SidebarItem,
    SidebarLabel,
    SidebarSection
} from "../catalyst-components/sidebar";
import {
    ListBulletIcon, TvIcon
} from '@heroicons/react/20/solid'

import GroupComponent from "./GroupComponent";
import ChannelsComponent from "./ChannelsComponent";
import {useDispatch, useSelector} from "react-redux";
import {settingsSelectTab} from "../actions";
import {useTranslation} from "react-i18next";

function TabsComponent() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const _selectedTabIndex = useSelector(state => state.settings.selectedTabIndex);
    const _parsedChannelCount = useSelector(state => state.playlist.parsedChannelCount);

    return (
        <>
            {_parsedChannelCount > 0 ?
            <SidebarBody className="bg-gray-50 dark:bg-zinc-900">
                <SidebarSection>
                    {_selectedTabIndex === 0 ? <GroupComponent /> : null}
                    {_selectedTabIndex === 1 ? <ChannelsComponent /> : null}
                </SidebarSection>
            </SidebarBody>
            : <SidebarBody/>}
            {_parsedChannelCount > 0 ?
            <SidebarFooter>
                <SidebarSection>
                    <SidebarItem href="#" current={_selectedTabIndex === 0} onClick={()=> dispatch(settingsSelectTab(0))}>
                        <ListBulletIcon/>
                        <SidebarLabel>{t('tabs.groups')}</SidebarLabel>
                    </SidebarItem>
                    <SidebarItem href="#" current={_selectedTabIndex === 1} onClick={()=> dispatch(settingsSelectTab(1))}>
                        <TvIcon/>
                        <SidebarLabel>{t('tabs.channels')}</SidebarLabel>
                    </SidebarItem>
                </SidebarSection>
            </SidebarFooter>
            : null}
        </>
    );
}

export default TabsComponent;
