import React, { useEffect } from 'react';
import {Navigate, Outlet, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {settingsLanguage} from "../actions";
import LayoutComponent from "./LayoutComponent";


const RouterComponent = () => {
    const stateLang = useSelector(state => state.settings.language);

    const dispatch = useDispatch();
    const { lang } = useParams();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (lang && i18n.language !== lang) {
            dispatch(settingsLanguage(lang));
        }
    }, [lang, i18n]);

    useEffect(() => {
        if (18n.language !== stateLang) {
            i18n.changeLanguage(stateLang);
        }
    }, [stateLang, i18n]);

    const darkMode = useSelector(state => state.settings.darkMode);

    const validLangs = ['en', 'de', 'fr', 'zh', 'es', 'ru', 'tr'];

    // Redirect or show an error if the language isn't valid
    if (!validLangs.includes(lang)) {
        return <Navigate to="/en" replace />;
    }


    return (
        <div className={darkMode ? 'dark' : 'light'}>
            <LayoutComponent>
                <Outlet />
            </LayoutComponent>
        </div>
    );
};

export default RouterComponent;
