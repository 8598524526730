import {Heading} from "../../catalyst-components/heading";
import {Divider} from "../../catalyst-components/divider";
import React from "react";
import {Text} from "../../catalyst-components/text";
import { useTranslation } from 'react-i18next';

export default function What() {
    const { t } = useTranslation();

    return (
        <div className="container mx-auto">
            <Heading>{t('what.whatIsPleyr')}</Heading>
            <Divider className="my-4"/>

            <Text>
                {t('what.descriptionPleyr')}
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('what.whatIsAnIPTVPlaylist')}</Heading>

            <Text>
                {t('what.descriptionIPTVPlaylist')}
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('what.whatIsIPTV')}</Heading>

            <Text>
                {t('what.descriptionIPTV')}
            </Text>

            <Divider soft className="my-4"/>

            <Heading level="2">{t('what.contactUs')}</Heading>

            <Text>
                {t('what.contactInfo')}
            </Text>
        </div>
    );
}
