import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import {
    startPlaylistDownload, endPlaylistDownload,
    percentUpdatePlaylistDownload, startPlaylistParse,
    endPlaylistParse, percentUpdatePlaylistParse,
    initChannels, playlistDownloadError
} from "../actions";
import Progress from "./Playlist/ProgressComponent";
import { Link } from "react-router-dom";
import Parser from '../lib/parser';
import {Subheading} from "../catalyst-components/heading";
import {Divider} from "../catalyst-components/divider";
import {Text} from "../catalyst-components/text";
import {Input} from "../catalyst-components/input";
import {Button} from "../catalyst-components/button";
import {Alert, AlertActions, AlertDescription, AlertTitle} from "../catalyst-components/alert";
import {useLocalizedNavigate} from "../lib/navigate";
import {useTranslation} from "react-i18next";

function PlaylistComponent({ url }) {
    const { t } = useTranslation();
    const [submit, setSubmit] = useState(false);
    const [alert, setAlert] = useState(false);
    const textInput = useRef(null);
    const dispatch = useDispatch();
    const pl = useSelector(state => state.playlist);
    const navigate = useLocalizedNavigate();

    const fetchPlaylist = (url) => {
        dispatch(startPlaylistDownload());
        const options = {
            onDownloadProgress: (progressEvent) => {
                dispatch(percentUpdatePlaylistDownload(progressEvent.loaded, progressEvent.total));
            }
        };

        axios.get(url, options)
            .then(response => {
                dispatch(endPlaylistDownload());
                parsePlaylist(response);
            })
            .catch(error => {
                setAlert(true);
                dispatch(playlistDownloadError(error.code));
            });
    };

    const parsePlaylist = (response) => {
        dispatch(startPlaylistParse());
        const data = response.data;
        const options = {
            onParseProgress: (progressEvent) => {
                dispatch(percentUpdatePlaylistParse(progressEvent.loaded, progressEvent.total));
            },
            onParseEnd: async (result) => {
                dispatch(endPlaylistParse());
                dispatch(initChannels(result));
                setSubmit(true);
            }
        };

        Parser.test(data, options);
    };

    return (
        <>
            <Alert open={alert} onClose={() => setAlert(false)}>
                <AlertTitle>{t('playlist.alerts.unableToDownload')}</AlertTitle>
                <AlertDescription>
                    {t('playlist.alerts.checkHowItWorks')} <Link to="/">{t('playlist.links.howItWorks')}</Link>
                </AlertDescription>
                <AlertActions>
                    <Button plain onClick={() => setAlert(false)}>
                        {t('playlist.buttons.ok')}
                    </Button>
                </AlertActions>
            </Alert>
            <section className="grid gap-x-8 gap-y-6 sm:grid-cols-2">
                <div className="space-y-1">
                    <Subheading>{t('playlist.labels.playlistURL')}</Subheading>
                    <Text>{t('playlist.descriptions.downloadAndParse')}</Text>
                </div>
                <div>
                    <Input ref={textInput} aria-label={t('playlist.labels.playlistURL')} name="url" defaultValue={url} />
                </div>
            </section>

            {pl.downloading ? (
                <>
                    <Divider className="my-10" soft />
                    <Progress processedCount={pl.downloadedSize} totalCount={pl.totalSize} progressingTitle={t('playlist.labels.downloading')} />
                </>
            ) : null}

            {pl.parsedChannelCount > 0 && submit ? (
                navigate("dash", { replace: true })
            ) : null}

            <Divider className="my-10" soft />

            <div className="flex justify-end gap-4">
                <Button type="button" onClick={() => fetchPlaylist(textInput.current.value)}>{t('playlist.buttons.loadPlaylist')}</Button>
            </div>
        </>
    );
}

export default PlaylistComponent;
