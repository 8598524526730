import React from "react";
import {Button} from "../../catalyst-components/button";
import {Cog8ToothIcon, BeakerIcon, LightBulbIcon, QuestionMarkCircleIcon} from "@heroicons/react/20/solid";
import {Text} from "../../catalyst-components/text";
import { useTranslation } from 'react-i18next';
import { Listbox, ListboxLabel, ListboxOption } from '../../catalyst-components/listbox'
import Flag from "react-flagpack";
import {useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const language = useSelector(state => state.settings.language);

    const countries = [
        {
            'code' : 'US',
            'lang' : 'en',
            'name': 'English'
        },
        {
            'code' : 'DE',
            'lang' : 'de',
            'name': 'Deutsch'
        },
        {
            'code' : 'ES',
            'lang' : 'es',
            'name': 'Español'
        },
        {
            'code' : 'ZH',
            'lang' : 'zh',
            'name': '中文'
        },
        {
            'code' : 'TR',
            'lang' : 'tr',
            'name': 'Türkçe'
        },
        {
            'code' : 'RU',
            'lang' : 'ru',
            'name': 'Русский'
        }
    ];

    const setLanguage = (l) => {
        navigate(`/${l}`, { replace: true });
    };
    return (
        <div className="flex">
            <div className="grow">
                <section>
                    <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
                        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-4xl dark:text-white">
                            {t('home.title')}
                        </h1>
                        <Text className="lg:text-lg mb-8">
                            {t('home.description')}
                        </Text>
                        <div className="mb-8 sm:space-x-4">
                            <Button color="light" href="settings"><Cog8ToothIcon/>{t('home.settings')}</Button>
                            <Button color="light" href="what"><QuestionMarkCircleIcon/>{t('home.what')}</Button>
                            <Button color="light" href="how"><LightBulbIcon/>{t('home.how')}</Button>
                            <Button color="light" href="try"><BeakerIcon/>{t('home.try')}</Button>
                        </div>
                    </div>
                </section>
            </div>
            <div className="flex-none w-28 max-lg:hidden">
                <Listbox onChange={(l) => setLanguage(l)} name="lang" value={language}>
                    {countries.map((country) => (
                        <ListboxOption value={country.lang} key={country.lang}>
                            <Flag className="w-5 sm:w-4" code={country.code}/>
                            <ListboxLabel>{country.name}</ListboxLabel>
                        </ListboxOption>
                    ))}
                </Listbox>
            </div>
        </div>

    );
}
